import * as THREE from 'three'
import React, { useRef, useState, useEffect } from 'react'
import { useFrame, MeshProps } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

type GLTFResult = GLTF & {
  nodes: {
    Curve: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshStandardMaterial
  }
}

type ActionName = 'CurveAction'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Model(props: JSX.IntrinsicElements['group']) {

  const ref = useRef<THREE.Mesh>()

  useFrame(() => {if (ref.current) {ref.current.rotation.z += 0.01}})

  const group = useRef<THREE.Group>()
  const { nodes, materials, animations } = useGLTF('0xnullobject.gltf') as GLTFResult
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        ref={ref}
        name="Curve"
        material={materials.Material}
        geometry={nodes.Curve.geometry}
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[60, 60, 60]}
      />
    </group>
  )
}

useGLTF.preload('/0xnullobject.gltf')
