import { useRef, useState, Suspense } from 'react'
import { Canvas, MeshProps, useFrame } from '@react-three/fiber'
import Model from './0xnullobject'

const Box: React.FC<MeshProps> = (props) => {
  // This reference will give us direct access to the mesh
  const mesh = useRef<THREE.Mesh>(null!)
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.y += 0.01))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial color={hovered ? 'grey' : 'black'} />
    </mesh>
  )
}

const Canvas3D = () => {
    return(
    <Suspense fallback={<h1>010101010001001</h1>}>
    <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Model/>
    </Canvas>
    </Suspense>
    )
}

export default Canvas3D