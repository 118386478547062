import React from 'react'
import './App.css'

import Canvas3D from './Canvas3D'
function App() {
  return (
    <div className="App">
      <div className="canvas">
      <Canvas3D/>
      </div>
      <header className="zerox-header">
        <p>
          COMING SOON
        </p>
        <p
          className="zerox-link"
        >
          v0.1.0(alpha)
        </p>
        <p
          className="zerox-footer"
        >
          dev / design by <a style={{display: 'inline'}}  href="https://www.instagram.com/stus.0x/">@stus.0x</a>
        </p>
      </header>
    </div>
  );
}

export default App;
